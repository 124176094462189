import { Injectable } from '@angular/core';
import { ModelFile as ModelFile } from '../models/model-file';
import { Observable, of, tap, zip, forkJoin, mergeMap } from 'rxjs';
import { FileUtilsService } from './file-utils.service';
import { map, switchMap } from 'rxjs/operators';

export enum INCLUDE_READBLE_FILES {
  AFI = 'afi',
  TXT = 'txt',
  INI = 'ini'
}

@Injectable({
  providedIn: 'root'
})
export class IntersectModelService {
  public static readonly afiOutputFilesExtensions = [
    'smpec',
    'smspec',
    'unsmry',
    'grid',
    'init3d',
    'prt',
    'frft',
    'init',
    'inspec',
    'out',
    'prtx',
    'rep',
    'msg',
    'rsm',
    'smpec',
    'unsmy',
    'rep',
    'progress',
    'rsgrid',
    'dbprtx',
    'rsinit',
    'csv',
    'inspec'
  ];

  constructor(private fileUtilsService: FileUtilsService) {}

  public getModelFilesInfoFromFiles(modelFile: File, files: File[]): Observable<ModelFile[]> {
    const eligibleFiles = files.filter(p => this.fileUtilsService.getFileExtension(p.name) !== `manifest`);
    return zip([this.getInputs(modelFile, eligibleFiles), this.getOutputs(modelFile, eligibleFiles)]).pipe(
      switchMap(([iFiles, oFiles]) => {
        const iofiles = iFiles.concat(oFiles);
        const filesLeftOut = files
          .filter(p => !iofiles.some(x => x.updateInfo.updatedFile.webkitRelativePath === p.webkitRelativePath))
          .map(p => {
            return this.fileUtilsService.getModelFileForAdd(p, 'output').pipe(tap(p => (p.updateInfo.optional = true)));
          });
        return zip(...iofiles.map(p => of(p)).concat(filesLeftOut));
      })
    );
  }

  public getInputs(modelFile: File, files: File[]): Observable<ModelFile[]> {
    return this.readAfiAndRelated(modelFile, files, true).pipe(
      switchMap(fileNames => {
        const inputFiles = files.filter(p =>
          fileNames.some(x => {
            if (!!this.fileUtilsService.getFileExtension(x))
              return p.webkitRelativePath.toLocaleLowerCase() === x.toLocaleLowerCase();
            //folder
            else return p.webkitRelativePath.toLocaleLowerCase().startsWith(x.toLocaleLowerCase()) ;
          })
        );
        let primaryFile = inputFiles.find(
          p => p.webkitRelativePath.toLocaleLowerCase() === modelFile.webkitRelativePath.toLocaleLowerCase()
        );
        if (!primaryFile) {
          inputFiles.push(modelFile);
        }
        const finalInputs = inputFiles.map(p => {
          return this.fileUtilsService.getModelFileForAdd(p, 'input').pipe(
            tap(mFile => {
              if (mFile.path.toLocaleLowerCase() === modelFile.webkitRelativePath.toLocaleLowerCase())
                mFile.isPrimaryFile = true;
            })
          );
        });
        if (finalInputs.length > 0) return zip(...finalInputs).pipe(map(inputs => inputs.filter(this.getUnique)));
        else of([]);
      })
    );
  }

  private getUnique(value: ModelFile, index: number, arr: ModelFile[]) {
    return arr.findIndex(p => p.path === value.path && p.type === value.type) === index;
  }

  public getOutputs(modelFile: File, files: File[]): Observable<ModelFile[]> {
    return of(true).pipe(
      switchMap(_ => {
        const fileNameWithoutExt = this.fileUtilsService.getFileNameWithoutExtension(modelFile.name);
        const possibleOutputFiles = files.filter(p =>
          IntersectModelService.afiOutputFilesExtensions.some(
            x => this.fileUtilsService.getFileExtension(p.name).toLocaleLowerCase() === x
          )
        );
        const outputFiles = possibleOutputFiles.filter(
          p => this.fileUtilsService.getFileNameWithoutExtension(p.name) === fileNameWithoutExt
        );
        const finalOutputs = outputFiles.map(p => {
          return this.fileUtilsService.getModelFileForAdd(p, 'output');
        });
        if (finalOutputs.length > 0) return zip(...finalOutputs).pipe(map(inputs => inputs.filter(this.getUnique)));
        else return of([]);
      })
    );
  }

  // the original one only read one afi file, and only care include, not others
  public readAfi(afiFile: File): Observable<string[]> {
    return this.fileUtilsService.readFile(afiFile).pipe(
      map(result => {
        const inputFiles = result?.match(/(?<=INCLUDE ")(?<!#\s{0,}INCLUDE ").*?(?=")/igm);
        const parentPath = this.fileUtilsService.getParentFolderPath(afiFile.webkitRelativePath);
        return inputFiles
          .filter(p => p.indexOf(':') === -1)
          .map(path => {
            let completePathWithoutVirtualPaths = path.split("/").filter(p => p.indexOf(".") != 0).join("/");
            return (!!parentPath ? `${parentPath}/${completePathWithoutVirtualPaths}` : completePathWithoutVirtualPaths);
          });
      })
    );
  }

  public readAfiAndGetSimulationType(afiFile: File): Observable<'afi' | 'fmgap' | 'ens'> {
    return this.fileUtilsService.readFile(afiFile).pipe(map(this.getSimulationType));
  }

  public getSimulationType(contents: string): 'afi' | 'fmgap' | 'ens' {
    let type: 'afi' | 'fmgap' | 'ens' = 'afi';
    if (contents?.match(/(?<=SIMULATION GAP)(?<!#\s{0,}SIMULATION GAP).*?(?=")/igm)?.length > 0) type = 'fmgap';
    else if (contents?.match(/(?<=SIMULATION ENS)(?<!#\s{0,}SIMULATION ENS).*?(?=")/igm)?.length > 0) type = 'ens';
    else if (contents?.match(/(?<=SIMULATION PIPESIM)(?<!#\s{0,}SIMULATION PIPESIM).*?(?=")/igm)?.length > 0) type = 'ens';
    return type;
  }

  // the new one can read afi file or any other readable file, care include and filename
  public readAfiAndRelated(afiFile: File, files: File[], isAfi: boolean): Observable<string[]> {
    const that = this;
    return this.fileUtilsService.readFile(afiFile).pipe(
      switchMap(result => {
        let finalNames: string[] = [];
        const inculdeNames = result?.match(/(?<=INCLUDE ")(?<!#INCLUDE ")(?<!#\s|\s[2]|\s[3]INCLUDE ").*?(?=")/igm);
        if (!!inculdeNames) {
          finalNames = finalNames.concat(inculdeNames);
        }
        if (!isAfi) {
          const filenameNames = result?.match(/(?<=FileName\s=\s").*?(?=")/igm);
          if (!!filenameNames) {
            finalNames = finalNames.concat(filenameNames);
          }
        }
        const parentPath = this.fileUtilsService.getParentFolderPath(afiFile.webkitRelativePath);

        if (finalNames.length > 0) {
          const nestedFiles = this.findReadableFiles(parentPath, finalNames, files);
          if (nestedFiles.length > 0) {
            const arrObs = nestedFiles.map(p => {
              let fileExtension = this.fileUtilsService.getFileExtension(p.webkitRelativePath);
              let isAfiReadableFiles = false;
              if( fileExtension === 'afi') {
                isAfiReadableFiles = true;
              }
              return that.readAfiAndRelated(p, files, isAfiReadableFiles)
            });
            return forkJoin(arrObs).pipe(
              map(p => {
                const concatNames = p
                  .flat()
                  .concat(
                    finalNames
                      .filter(p => p.indexOf(':') === -1)
                      .map(name => {
                        //let newName = name;
                        //if(name.startsWith("./")) {
                        //  newName = name.substring(2)
                        //}
                        //return (!!parentPath ? `${parentPath}/${newName}` : newName)
                        return that.filePathMapCase(name, parentPath);
                      })
                  );
                return concatNames;
              })
            );
          } else {
            return of(
              finalNames.filter(p => p.indexOf(':') === -1)
              .map(name => {
                //let newName = name;
                //if(name.startsWith("./")) {
                //  newName = name.substring(2)
                //}
                //return (!!parentPath ? `${parentPath}/${newName}` : newName)
                return that.filePathMapCase(name, parentPath);
              })
            );
          }
        } else {
          return of([]);
        }
      })
    );
  }

  private filePathMapCase(name, parentPath) {
    let newName = name;
    if(name.startsWith("./")) {
      newName = name.substring(2)
    }
    return (!!parentPath ? `${parentPath}/${newName}` : newName)
  }


  // forkjoin all
  public readAllInputFiles(files: File[]): Observable<string[]> {
    let streams = [];
    files.forEach(oneFile => {
      streams.push(this.readOneInputFile(oneFile));
    });
    return forkJoin(streams);
  }

  public readOneInputFile(oneFile: File): Observable<string[]> {
    return new Observable(subscriber => {
      try {
        const reader = new FileReader();
        reader.onerror = () => {
          reader.abort();
          console.error('problem parsing oneinput file.', oneFile);
          subscriber.error(new Error('Problem parsing input file.'));
        };
        reader.onload = () => {
          //extracting json from text https://stackoverflow.com/questions/21994677/find-json-strings-in-a-string
          const result: any = reader.result;
          let includeFiles = result?.match(/(?<=INCLUDE ")(?<!\s{0,}#INCLUDE ").*?(?=")/igm);
          let filenameFiles = result?.match(/(?<=FileName\s=\s").*?(?=")/igm);
          let inputFiles: string[] = [];
          if (!!includeFiles) {
            inputFiles = inputFiles.concat(includeFiles);
          }
          if (!!filenameFiles) {
            inputFiles = inputFiles.concat(filenameFiles);
          }

          subscriber.next(inputFiles.filter(p => p.indexOf(':') === -1));
          subscriber.complete();
        };
        reader.readAsText(oneFile);
      } catch (e) {
        console.error('error reading oneinput file.', oneFile);
        subscriber.error(e);
      }
    });
  }

  // find readables inside the nestedfiles
  public findReadableFiles(parentPath: string, nestedFilePaths: string[], files: File[]): File[] {
    const filteredPaths = nestedFilePaths
      .filter(x => {
        let fileExtension = this.fileUtilsService.getFileExtension(x);
        //use enumerate if((<any>Object).values(INCLUDE_READBLE_FILES).includes(fileExtension)) {
        if (fileExtension === 'ixf' || fileExtension === 'txt' || fileExtension === 'ini' || fileExtension === 'afi') {
          return true;
        } else {
          return false;
        }
      })
      .map(p =>  {
        if(p.startsWith("./")) {
          return `${parentPath}/${p.substring(2)}`
        }
        return `${parentPath}/${p}`
      });

    const filteredFiles = files.filter(x => filteredPaths.includes(x.webkitRelativePath));
    return filteredFiles;
  }
}
