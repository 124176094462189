import { Injectable } from '@angular/core';
import { catchError, concatMap, defer, EMPTY, expand, from, last, Observable, of, tap, throwError, zip } from 'rxjs';
import { filter, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { FileUtilsService } from './file-utils.service';
import { environment } from '../../../environments/environment';
import { FileService } from '../api/file.service';
import { SubTaskInProgress, TaskInProgress } from '../models/task-in-progress';
import {
  ModelDownloadTaskInProgress,
  ModelDownloadTaskInProgressService
} from './model-download-task-in-progress.service';

export class FileSaveRequest {
  id: string;
  filePath: string;
  fileName: string;
  blobUrl: string;
  fileSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class FilesWriterService {
  public constructor(private fileUtilsService: FileUtilsService, private fileService: FileService) {}

  public getDirectoryHandle(): Observable<any> {
    return defer(() => (window as any).showDirectoryPicker());
  }

  public requestPermission(dirHandle: any, permissionOptions: { mode: string }): Observable<any> {
    return defer(() => dirHandle.requestPermission(permissionOptions));
  }

  public getDirectoryHandleWithPermission(permissionOptions: { mode: string }): Observable<any> {
    return this.getDirectoryHandle().pipe(
      switchMap(p => this.requestPermission(p, permissionOptions).pipe(map(_ => p)))
    );
  }

  downloadBlobsForFileAndWriteToStream(
    writeableStream: any,
    file: FileSaveRequest,
    progressCallBack: (subTaskInProgress: ModelDownloadTaskInProgress) => void
  ): Observable<boolean> {
    let totalSize = 0;
    const defaultWriter = writeableStream.getWriter();
    return defer(() => defaultWriter.ready).pipe(
      switchMap(_ => {
        const sizesToBeRead = this.fileUtilsService.getSizesToBeReadInChunks(
          file.fileSize,
          environment.maxFileUploadChunkSize
        );
        return from(sizesToBeRead.length === 0 ? [[0, 1]] : sizesToBeRead).pipe(
          concatMap(([startRead, endRead]) => {
            return this.fileService.getChunk(file.blobUrl, startRead, endRead - 1).pipe(
              switchMap(res => {
                const reader = res.body.getReader();
                return defer(() => reader.read()).pipe(
                  expand(project => {
                    totalSize = this.projectValueCase(project, defaultWriter, file, totalSize, progressCallBack);
                    // if (project.value) {
                    //   defaultWriter.write(project.value);
                    //   totalSize = totalSize + project.value.byteLength;
                    //   if (file.fileSize) {
                    //     progressCallBack({
                    //       Id: file.id,
                    //       Progress: (totalSize / file.fileSize) * 100,
                    //       Status: ModelDownloadTaskInProgressService.TaskInProgressStatus
                    //     } as ModelDownloadTaskInProgress);
                    //   }
                    // }
                    if (project.done) {
                      if (file.fileSize === 0) file.fileSize = totalSize;
                      progressCallBack({
                        Id: file.id,
                        Progress: (totalSize / file.fileSize) * 100,
                        Status: ModelDownloadTaskInProgressService.TaskInProgressStatus
                      } as ModelDownloadTaskInProgress);
                      return EMPTY;
                    }
                    return defer(() => reader.read());
                  })
                );
              })
              // tap(res => {
              //   if (file.fileSize === 0) file.fileSize = (res.body as any).size;
              //   progressCallBack({
              //     Id: file.id,
              //     Progress: (endRead / file.fileSize) * 100,
              //     Status: ModelDownloadTaskInProgressService.TaskInProgressStatus
              //   } as ModelDownloadTaskInProgress);
              // })
            );
          }),
          // map(res => {
          //   // const reader = res.body.getReader();
          //   // defaultWriter.write(reader);
          //   return true;
          // }),
          last(),
          switchMap(_ => {
            return defer(() => defaultWriter.ready).pipe(map(_ => true));
          })
        );
      }),
      tap(_ => {
        defaultWriter.close();
        progressCallBack({
          Id: file.id,
          Progress: 100,
          Status: ModelDownloadTaskInProgressService.TaskSuccessStatus
        } as ModelDownloadTaskInProgress);
      }),
      catchError(err => {
        progressCallBack({
          Id: file.id,
          Progress: 100,
          Status: ModelDownloadTaskInProgressService.TaskFailureStatus
        } as ModelDownloadTaskInProgress);
        defaultWriter.close();
        console.error('downloadModelFile', file, err);
        return throwError(err);
      })
    );
  }

  private projectValueCase(project: ReadableStreamReadResult<Uint8Array>, defaultWriter: any, file: FileSaveRequest, totalSize: number, 
    progressCallBack: (subTaskInProgress: ModelDownloadTaskInProgress) => void)
  {
    if (project.value) {
      defaultWriter.write(project.value);
      totalSize = totalSize + project.value.byteLength;
      if (file.fileSize) {
        progressCallBack({
          Id: file.id,
          Progress: (totalSize / file.fileSize) * 100,
          Status: ModelDownloadTaskInProgressService.TaskInProgressStatus
        } as ModelDownloadTaskInProgress);
      }
    }

    return totalSize;
  }

  writeFiles(
    rootDirHandle: any,
    files: FileSaveRequest[],
    progressCallBack: (subTaskInProgress: ModelDownloadTaskInProgress) => void
  ): Observable<FileSaveRequest[]> {
    const rootDirNames = [...new Set(files.map(p => p.filePath.split('/')[0]))];
    return from(rootDirNames).pipe(
      mergeMap(rootDirName => {
        const filesInRootFolder = files.filter(p => p.filePath.split('/')[0] === rootDirName);
        if (rootDirName === '') rootDirName = uuidv4();
        return this.writeForRootFolder(rootDirHandle, filesInRootFolder, rootDirName, progressCallBack);
      }, 2),
      toArray(),
      map(arrFileSaveRequests => arrFileSaveRequests.flat())
    );
  }

  writeForRootFolder(
    rootParentDirHandle: any,
    filesInRootFolder: FileSaveRequest[],
    rootDirName: string,
    progressCallBack: (subTaskInProgress: ModelDownloadTaskInProgress) => void
  ): Observable<FileSaveRequest[]> {
    return this.recursivelyCreateOrGetFolder(rootParentDirHandle, rootDirName, true).pipe(
      switchMap(parentHandle =>
        from(filesInRootFolder.map(p => [p, parentHandle])).pipe(
          mergeMap(([fileInRootFolder, parentHandle]) => {
            const remainingPath = fileInRootFolder.filePath.split('/');
            remainingPath.shift(); //remove the folder wrt the parentHandle
            return this.getDirHandleForFilePath(parentHandle, remainingPath.join('/')).pipe(
              switchMap((dirHandle: any) => this.getWriteableStream(dirHandle, fileInRootFolder.fileName)),
              switchMap((writeableStream: any) =>
                this.downloadBlobsForFileAndWriteToStream(writeableStream, fileInRootFolder, progressCallBack).pipe(
                  map(_ => fileInRootFolder),
                  catchError(err => {
                    console.error('error writing a file', filesInRootFolder, err);
                    return of(null);
                  })
                )
              )
            );
          }, environment.maxFileChunkUploadsConcurrently),
          filter(p => !!p),
          toArray()
        )
      )
    );
  }

  public getWriteableStream(dirHandle: any, fileName: string): Observable<any> {
    return defer(() => dirHandle.getFileHandle(fileName, { create: true })).pipe(
      switchMap((fileHandle: any) => {
        return defer(() => fileHandle.createWritable());
      })
    );
  }

  public getDirHandleForFilePath(fromDirHandle: any, filePath: string): Observable<any> {
    if (!filePath) return of(fromDirHandle);
    const parts = filePath.split('/');
    let index = 0;
    return this.recursivelyCreateOrGetFolder(fromDirHandle, parts[index++], false)
      .pipe(
        expand(dirHandle => {
          if (index > parts.length - 1) return EMPTY;
          return this.recursivelyCreateOrGetFolder(dirHandle, parts[index++], false);
        })
      )
      .pipe(last());
  }

  public recursivelyCreateOrGetFolder(
    parentDirHandle: any,
    folderName: string,
    attemptUnique: boolean
  ): Observable<any> {
    let attempt = 0;
    let attemptFolderName = folderName;
    return defer(() => parentDirHandle.getDirectoryHandle(folderName, { create: false }))
      .pipe(
        expand(dirHandle => {
          if (!attemptUnique) return EMPTY;
          attempt++;
          attemptFolderName = `${folderName}-${attempt}`;
          return defer(() => parentDirHandle.getDirectoryHandle(attemptFolderName, { create: false }));
        }),
        catchError(err => {
          return defer(() => parentDirHandle.getDirectoryHandle(attemptFolderName, { create: true }));
        })
      )
      .pipe(last());
  }
}
