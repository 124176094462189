import * as _ from 'lodash';
import {Injectable} from "@angular/core";
import {FileUtilsService} from "./file-utils.service";
import {ModelFile as ModelFile} from "../models/model-file";
import {Simulation} from "../models/export class simulation";
import {AuthService} from "../auth/auth.service";
import {Observable, of, zip} from "rxjs";
import {IntersectModelService} from "./intersect-model.service";
import {CoupledModelService} from "./coupled-model.service";
import { MbalModelService } from './mbal-model.service';
import {map, switchMap} from "rxjs/operators";
import { Collection } from "../models/collection";


@Injectable({
  providedIn: 'root'
})
export class ModelFilesService {
  public static readonly newId = '-0000-00-00-0000-';
  public static readonly IntersectExtension = 'afi';
  public static readonly CoupledExtension = 'rsl';
  public static readonly MbalExtension = 'gap';


  constructor(private fileUtilsService: FileUtilsService, private authService: AuthService, 
    private intersectModelService: IntersectModelService, private coupledModelService: CoupledModelService, 
    private mbalModelService: MbalModelService) {

  }

  public getModelsInFiles(files: File []) {
    //const allFileExtensionsAllowed = [ModelFilesService.IntersectExtension, ModelFilesService.CoupledExtension, 
    //  MbalModelService.MbalExtension1, MbalModelService.MbalExtension2];
    const allFileExtensionsAllowed = [ModelFilesService.IntersectExtension, ModelFilesService.CoupledExtension];
    const rootFiles = this.fileUtilsService.getFilesAtRootFolder(files);
    const modelFiles = rootFiles.filter(p => allFileExtensionsAllowed.some(q => q === this.fileUtilsService.getFileExtension(p.name)));
    const mbalModelFiles = this.mbalModelService.getModelsInFiles(files);
    return modelFiles.concat(mbalModelFiles);
  }

  public getModelFilesInfoFromFiles(modelFile: File, files: File[]): Observable<ModelFile[]> {
    return of(true).pipe(switchMap(() => {
      const fileExtension = this.fileUtilsService.getFileExtension(modelFile.name);
      if (fileExtension === ModelFilesService.IntersectExtension) {
        return this.intersectModelService.getModelFilesInfoFromFiles(modelFile, files);
      } else if (fileExtension === ModelFilesService.CoupledExtension) {
        return this.coupledModelService.getModelFilesInfoFromFiles(modelFile, files);
      } else if (fileExtension === MbalModelService.MbalExtension1 || 
        fileExtension === MbalModelService.MbalExtension2 || fileExtension === MbalModelService.MbalExtension3) {
        return this.mbalModelService.getModelFilesInfoFromFiles(modelFile, files);
      } else {
        console.error('unknown model file', modelFile.webkitRelativePath);
        return of([]);
      }
    }));
  }

  public getMissingInputFiles(modelFile: File, files: File[]): Observable<any> {
    let afiFiles = [modelFile];
    const fileExtension = this.fileUtilsService.getFileExtension(modelFile.name);
    if (fileExtension === MbalModelService.MbalExtension1 || 
      fileExtension === MbalModelService.MbalExtension2 || fileExtension === MbalModelService.MbalExtension3) {
      return this.mbalModelService.getMissingInputFiles(files);
    } 

    if (fileExtension === ModelFilesService.CoupledExtension) {
      afiFiles = this.coupledModelService.getAfiFiles(files)
    }
    
    return zip(afiFiles.map(afiFile => this.intersectModelService.readAfi(afiFile))).pipe(map((p: string[][]) => {
      return p.flat().filter(p => !!this.fileUtilsService.getFileExtension(p));
    }), switchMap(fileNames => {
      let missingInputFiles =  [];
      missingInputFiles = _.differenceWith(fileNames, files, this.comparatorFunctionForWarningMessage);
        return of(missingInputFiles);
    }));
  }

  public comparatorFunctionForWarningMessage = (inputFile, allFile ) => {
    if(allFile.webkitRelativePath){
      return inputFile.toLocaleLowerCase() === allFile.webkitRelativePath.toLocaleLowerCase()
    }
  }

  public composeManifest(modelFiles: ModelFile[]): { filePath: string, content: string } {
    const manifest = {
      RootFile: {Path: modelFiles.find(p => p.isPrimaryFile)?.path},
      InputFiles: modelFiles.filter(p => p.type === 'input').map(p => {
        return {Path: p.path };
      }),
      OutputFiles: modelFiles.filter(p => p.type === 'output').map(p => {
        return {Path: p.path };
      })
    };

    if (!manifest.RootFile?.Path) throw new Error('no root file found');

    manifest.InputFiles.push({Path: `${manifest.RootFile.Path}.manifest`});

    return {filePath: `${manifest.RootFile.Path}.manifest`, content: JSON.stringify(manifest)};
  }


  public updateManifest(manifestContent: string, modelFiles: ModelFile[]): string {
    const manifest = JSON.parse(manifestContent) as { RootFile: { Path: string }, InputFiles: { Path: string }[], OutputFiles: { Path: string }[] };

    modelFiles.forEach(modelFile => {
      switch (modelFile.updateInfo?.mode) {
        case 'add':
          if (modelFile.type === 'input') {
            manifest.InputFiles.push({Path: modelFile.path});
          } else {
            manifest.OutputFiles.push({Path: modelFile.path});
          }
          break;
        case "update":
          break;
        case 'delete':
          if (modelFile.type === 'input')
            manifest.InputFiles = manifest.InputFiles.filter(p => p.Path !== modelFile.path);
          else
            manifest.OutputFiles = manifest.OutputFiles.filter(p => p.Path !== modelFile.path);
          break;
        case "not-allowed":
          break;
      }
    });

    return JSON.stringify(manifest);
  }

  public createModelInfoFromModelFile(collection: Collection, modelFile: File): Simulation {
    const model: Simulation = {
      name: this.fileUtilsService.getNameNoSpecialCharacter(modelFile.webkitRelativePath),
      id: ModelFilesService.newId,
      collectionId: collection.id,
      collectionName: collection.name,
      createdUserId: this.authService.currentUser.username,
      createdDatetime: new Date(),
      updateInfo: {mode: 'add'},
    } as Simulation;
    return model;
  }


}
